<template>
  <div class="app app-container clearfix">
    <div class="common-content content">
      <el-row>
        <el-col :span="16" :xs="0" :sm="16" :md="16" :lg="16" :xl="16">
          <div class="content_left">
            <h4>We Are Everywere</h4>
            <img src="../assets/images/map.jpg" alt>
          </div>
        </el-col>
        <el-col :span="8" :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="content_right">
            <h4>Write Us</h4>
            <form action @submit.prevent="submit">
              <el-row>
                <el-col :span="24">
                  <span>您的姓名</span>
                </el-col>
                <el-col :span="24">
                  <el-input id="name" v-model="uname" placeholder="请输入姓名"></el-input>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <span>联系方式</span>
                </el-col>
                <el-col :span="24">
                  <el-input id="phone" v-model="utel" placeholder="请输入号码"></el-input>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <span>您的留言</span>
                </el-col>
                <el-col :span="24">
                  <el-input class="ly" id="message" type="textarea" :rows="5" placeholder="请输入内容" v-model="umessage"></el-input>
                </el-col>
              </el-row>
              <el-row>
                <!--<el-col :span="24">-->
                <!--<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
                <!--</el-col>-->
                <el-col :span="24">
                  <el-input class="" id="btn" type="submit">提交</el-input>
                </el-col>
              </el-row>
            </form>
          </div>
        </el-col>
      </el-row>
      <div class="content_foot">
        <el-row>
          <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="10">
            <p>ADDRESS</p>
            <b>上海市崇明区新村乡耀洲路 741 号 5 幢 4615 室</b>
          </el-col>
          <el-col :xs="24" :sm="7" :md="7" :lg="8" :xl="7">
            <p>PHONE</p>
            <b>13117416668</b>
          </el-col>
          <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
            <p>E-MAIL</p>
            <b>caikutong@dghang.com</b>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <span>{{errorMsg}}</span>
      <span slot="footer" class="dialog-footer">
        <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data () {
    return {
      centerDialogVisible: false,
      uname: '',
      utel: '',
      umessage: '',
      errorMsg: ''
    }
  },
  methods: {
    // 表单提交
    submit () {
      let reg = /^1[34578]\d{9}$/
      if (!this.uname) {
        this.errorMsg = '请输入正确的姓名'
        this.centerDialogVisible = true
        return
      }
      if (!reg.test(this.utel)) {
        this.errorMsg = '请输入正确的号码'
        this.centerDialogVisible = true
        return
      }
      if (!this.umessage) {
        this.errorMsg = '请输入正确的信息'
        this.centerDialogVisible = true
        return
      }
      let obj = {
        name: this.uname,
        contact: this.utel,
        message: this.umessage
      }
      var objs = qs.stringify(obj)
      this.addCus(objs)
    },
    addCus (val) {
      this.$store.dispatch('addCus', val).then(res => {
        console.log(res)
        this.uname = ''
        this.utel = ''
        this.umessage = ''
      })
    }
  }
}
</script>
<style lang="less">
  /*当前面使用的全局CSS*/
  @current-input-btn-color: #1ecfe1;
  .app-container {
    flex: 1;
    padding: 20% 0 3% 0;
    background: url("../assets/images/cont_bg.jpg") no-repeat;
    background-size: 100%;
    background-color: #fafafa;
    .content {
      width: 70%;
      padding: 3% 5%;
      background: @global-white;
      font-size: .88rem;
      text-align: left;
      h4 {
        color: @global-black;
      }
      span {
        color: @global-black;
      }
      .content_left {
        img {
          width: 100%;
          margin-left: -2.5rem;
          height: 100%;
        }
      }
      .content_right {
        form {
          margin-top: 1.3rem;
          span {
            margin-left: .6rem;
          }
          input,.ly {
            background: #edf1f4;
            border: 1px solid #edf1f4;
            border-radius: 3px;
            width: 70%;
            margin-bottom: .75rem;
            margin-top: .25rem;
          }
          .ly {
            textarea {
              background: #edf1f4;
              resize: none;
            }
          }
          #btn {
            width: 71%;
            height: 2.5rem;
            line-height: 2.5rem;
            background: @current-input-btn-color;
            color: @global-white;
            border: 1px solid @current-input-btn-color;
          }
        }
      }
      .content_foot {
        p {
          margin-right: 1rem;
          .textnowrap;
          font-size: 0.75rem;
        }
        b {
          font-weight: normal;
          .textwrap;
          color: @global-black;
          width: 95%;
          display: inline-block;
        }
      }
    }
  }
  @media screen and (max-width: 767px){
    .app-container {
      .content {
        .content_foot {
          .el-col {
            .flex;
            align-items: normal;
            p {
              margin: 0 .5rem 0 0;
            }
          }
        }
      }
    }
  }
</style>
